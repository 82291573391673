var init = function() {
    "use strict";

    let n = {
        states: {}
    };

    // set cookie exparation
    const t = function() {
        ! function(n, t) {
            const a = new Date;
            a.setTime(a.getTime() + 24 * e * 60 * 60 * 1e3);
            let s = "expires=" + a.toUTCString();
            document.cookie = n + "=" + t + ";" + s + ";path=/"
        }("dsw", JSON.stringify(n))
    }

    let e = function(n) {
        let t = n + "=",
            e = decodeURIComponent(document.cookie).split(";");
        for (let n = 0; n < e.length; n++) {
            let a = e[n];
            for (;
                " " == a.charAt(0);) a = a.substring(1);
            if (0 == a.indexOf(t)) return a.substring(t.length, a.length)
        }
        return ""
    }("dsw");

    try {
        e = JSON.parse(e)
    } catch (n) {
    }

    n = {
        states: {},
        ...e
    }

    let a = ["format_size", "add", "remove", "restart_alt", "close"];

    // generate clickable div tag
    const s = function(t, e) {
        let s = "";

        for (var i = t.length; i--;) {
            let o = t[i],
                l = n.states[o.key];

            "dsw-filter" == e && n.states.contrast == o.key && (l = !0),
            s += `
                <div
                  class="dsw-btn ${e||""} ${l?"dsw-selected":""}"
                  role="button"
                  aria-pressed="false"
                  data-key="${o.key}"
                  arai-label="${o.label}"
                  title="${o.label}"
                >
                    <span class="material-icons">${o.icon}</span>
                    ${o.label}
                </div>
            `, a.push(o.icon)
        }
        return s
    }

    let i = s([
        {
            label: "Readable Font",
            key: "readable-font",
            icon: "local_parking"
        },
        {
            label: "Highlight Links",
            key: "highlight-links",
            icon: "link"
        },
        {
            label: "Highlight Title",
            key: "highlight-title",
            icon: "title"
        }
    ]);

    let o = s([
        {
            label: "Monochrome",
            key: "monochrome",
            icon: "filter_b_and_w"
        },
        {
            label: "Low Saturation",
            key: "low-saturation",
            icon: "gradient"
        },
        {
            label: "High Saturation",
            key: "high-saturation",
            icon: "filter_vintage"
        },
        {
            label: "High Contrast",
            key: "high-contrast",
            icon: "tonality"
        },
        {
            label: "Light Contrast",
            key: "light-contrast",
            icon: "brightness_5"
        },
        {
            label: "Dark Contrast",
            key: "dark-contrast",
            icon: "nightlight"
        }
    ], "dsw-filter");

    let l = s([
        {
            label: "Big Cursor",
            key: "big-cursor",
            icon: "mouse"
        },
        {
            label: "Stop Animations",
            key: "stop-animations",
            icon: "motion_photos_off"
        },
        {
            label: "Reading Guide",
            key: "readable-guide",
            icon: "local_library"
        }
    ], "dsw-tools");

    var r = document.createElement("div");

    r.innerHTML = `
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons&text=${a.toString()}" rel="stylesheet">
        <style>
            .dsw-widget {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                font-family: Lato,sans-serif;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
            }

            .dsw-widget * {
                box-sizing: border-box;
            }

            .dsw-menu-btn {
                position: fixed;
                z-index: 500000;
                left: 20px;
                bottom: 20px;
                background: #0048ff;
                box-shadow: 0 5px 15px 0 rgb(37 44 97 / 15%), 0 2px 4px 0 rgb(93 100 148 / 20%);
                transition: .3s;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                transform: translateY(0);
                width: 64px;
                height: 64px;
                display: flex;
                fill: white;
                cursor: pointer;
            }

            .dsw-menu-btn !important {
                background: transparent !important;
            }

            .dsw-menu-btn:hover {
                transform: scale(1.05);
            }

            .dsw-menu {
                display: none;
                position: fixed;
                left: 20px;
                top: 20px;
                border-radius: 8px;
                box-shadow: -1px 0 20px -14px #000;
                opacity: 1;
                transition: .3s;
                z-index: 500000;
                overflow: hidden;
                background: #fff;
                width: 500px;
                line-height: 1;
                font-size: 14px;
                height: calc(100% - 40px - 75px);
                letter-spacing: 0.015em;
            }

            .dsw-menu-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #0334b1;
                color: white;
                padding-left: 12px;
                font-weight: 600;
            }

            .dsw-menu-header > div {
                display: flex;
            }

            .dsw-menu-header div[role="button"] {
                padding: 12px;
            }

            .dsw-menu-header div[role="button"]:hover {
                opacity: 0.8;
            }

            .dsw-items {
                display: flex;
                margin: -8px;
                padding: 0;
                list-style: none;
                flex-wrap: wrap;
            }

            .dsw-btn {
                width: 140px;
                height: 120px;
                border-radius: 8px;
                margin: 8px;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                color: #333;
                font-size: 14px !important;
                background: #ecf3ff;
                border: 3px solid #ecf3ff;
                transition: background-color 0.3s ease;
                line-height:1.3;
            }

            .dsw-btn .material-icons {
                margin-bottom: 16px;
            }

            .dsw-btn:hover {
                border-color: #0048ff;
            }

            .dsw-btn.dsw-selected {
                background: #0048ff;
                color: white;
                border-color: #0048ff;
            }

            .dsw-plus:hover,
            .dsw-minus:hover {
                opacity: 0.8;
            }

            .dsw-menu-content {
                overflow: scroll;
                max-height: calc(100% - 55px);
            }

            .dsw-card {
                margin: 0 15px 30px;
            }

            .dsw-card-title {
                font-size: 18px;
                padding: 15px 0;
            }

            .dsw-adjust-font {
                background: #ecf3ff;
                padding: 20px 25px;
                margin-bottom: 16px;
            }

            .dsw-adjust-font label {
                display: flex;
                align-items: center;
            }

            .dsw-adjust-font > div {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
            }

            .dsw-adjust-font div[role="button"] {
                background: #0648ff;
                border-radius: 50%;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }

            .dsw-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10000;
                display: none;
            }

            @media only screen and (max-width: 550px) {
                .dsw-menu {
                    width: calc(100vw - 20px);
                    left: 10px;
                }

                .dsw-btn {
                    width: calc(25% + 12px);
                    margin: 4px;
                }
              }
        </style>

        <div class="dsw-widget">
            <div class="dsw-menu-btn" title="Open Accessibility Menu" role="button" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="34px" height="34px" >
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M20.5 6c-2.61.7-5.67 1-8.5 1s-5.89-.3-8.5-1L3 8c1.86.5 4 .83 6 1v13h2v-6h2v6h2V9c2-.17 4.14-.5 6-1l-.5-2zM12 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/>
                </svg>
            </div>
            <div class="dsw-menu">
                <div class="dsw-menu-header">
                    Accessibility Menu
                    <div>
                        <div role="button" class="dsw-menu-reset"  title="Reset Settings">
                            <span class="material-icons">
                                restart_alt
                            </span>
                        </div>
                        <div role="button" class="dsw-menu-close" title="Close Accessibility Menu">
                            <span class="material-icons">
                                close
                            </span>
                        </div>
                    </div>

                </div>
                <div class="dsw-menu-content">
                    <div class="dsw-card" style="margin-top: 15px;">
                        <div class="dsw-card-title">
                            Content Adjustments
                        </div>

                        <div class="dsw-adjust-font">
                            <label>
                                <span class="material-icons" style="margin-right:8px;">
                                    format_size
                                </span>
                                Adjust Font Size
                            </label>
                            <div>
                                <div class="dsw-minus" data-key="font-size" role="button" aria-pressed="false">
                                    <span class="material-icons">
                                        remove
                                    </span>
                                </div>
                                <div class="dsw-amount">
                                    ${n.states.fontSize&&1!=n.states.fontSize?`${parseInt(100*n.states.fontSize)}%`:"Default"}
                                </div>
                                <div class="dsw-plus" data-key="font-size" role="button" aria-pressed="false">
                                    <span class="material-icons">
                                        add
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="dsw-items">
                            ${i}
                        </div>
                    </div>

                    <div class="dsw-card" style="margin-top: 15px;">
                        <div class="dsw-card-title">
                            Color Adjustments
                        </div>
                        <div class="dsw-items">
                            ${o}
                        </div>
                    </div>

                    <div class="dsw-card" style="margin-top: 15px;">
                        <div class="dsw-card-title">
                            Tools
                        </div>
                        <div class="dsw-items">
                            ${l}
                        </div>
                    </div>
                </div>
            </div>

            <div class="dsw-overlay">
            </div>
        </div>
    `;

    const d = function(n, t) {
            let e = document.getElementById(t || "") || document.createElement("style");
            e.innerHTML = n, e.id || (e.id = t, document.head.appendChild(e))
        }

    const c = function(n, t) {
            let e = "",
                a = ["-o-", "-ms-", "-moz-", "-webkit", ""];
            for (var s = a.length; s--;) e += a[s] + (t || "filter") + ":" + n + ";";
            return e
        }

    const u = function(n) {
        let t = "";
        if (n) {
            let a = "";
            "dark-contrast" == n ? a = "color: #fff !important;fill: #FFF !important;background-color: #000 !important;" : "light-contrast" == n ? a = " color: #000 !important;fill: #000 !important;background-color: #FFF !important;" : "high-contrast" == n ? a += c("contrast(125%)") : "high-saturation" == n ? a += c("saturate(200%)") : "low-saturation" == n ? a += c("saturate(50%)") : "monochrome" == n && (a += c("grayscale(100%)"));
            let s = [""];
            "dark-contrast" != n && "light-contrast" != n || (s = ["h1", "h2", "h3", "h4", "h5", "h6", "img", "p", "i", "svg", "a", "button", "label", "li", "ol"]);
            for (var e = s.length; e--;) t += '[data-dsw-filter="' + n + '"] ' + s[e] + "{" + a + "}"
        }
        d(t, "dsw-filter-style"), n ? document.documentElement.setAttribute("data-dsw-filter", n) : document.documentElement.removeAttribute("data-dsw-filter", n)
    }

    const p = function() {
        let t = [
            {
                id: "highlight-title",
                childrenSelector: ["h1", "h2", "h3", "h4", "h5", "h6"],
                css: "outline: 2px solid #0048ff !important;outline-offset: 2px !important;"
            },
            {
                id: "highlight-links",
                childrenSelector: ["a[href]"],
                css: "outline: 2px solid #0048ff !important;outline-offset: 2px !important;"
            },
            {
                id: "readable-font",
                childrenSelector: ["", "h1", "h2", "h3", "h4", "h5", "h6", "img", "p", "i", "svg", "a", "button", "label", "li", "ol"],
                css: "font-family: Arial,Helvetica,sans-serif !important;"
            }
        ];
        let e = "";

        for (var a = t.length; a--;) {
            let i = t[a];
            if (document.documentElement.classList.toggle(i.id, !!n.states[i.id]), n.states[i.id]) {
                for (var s = i.childrenSelector.length; s--;) {
                    e += "." + i.id + " " + i.childrenSelector[s] + "{" + i.css + "}"
                }
            }
        }

        var i = document.querySelector(".dsw-rg-container");

        if (n.states["readable-guide"]) {
            if (!i) {
                var o = document.createElement("div");
                o.setAttribute("class", "dsw-rg-container"), o.innerHTML = `
                  <style>
                      .dsw-rg {
                          position: fixed;
                          top: 0;
                          left: 0;
                          right: 0;
                          width: 100%;
                          height: 0;
                          pointer-events: none;
                          background-color: rgba(0,0,0,.5);
                          z-index: 1000000;
                      }
                  </style>
                  <div class="dsw-rg dsw-rg-top"></div>
                  <div class="dsw-rg dsw-rg-bottom" style="top: auto;bottom: 0;"></div>
                `;
                let n = o.querySelector(".dsw-rg-top"),
                    t = o.querySelector(".dsw-rg-bottom"),
                    e = 20;
                window.onScrollReadableGuide = function(a) {
                    n.style.height = a.clientY - e + "px", t.style.height = window.innerHeight - a.clientY - e - e + "px"
                }, document.addEventListener("mousemove", window.onScrollReadableGuide, !1), document.body.appendChild(o)
            }
        } else {
            i && (i.remove(), document.removeEventListener("mousemove", window.onScrollReadableGuide));
        }

        n.states["stop-animations"] && (e += `
            body * {
                ${c("none !important","transition")}
                ${c("forwards !important","animation-fill-mode")}
                ${c("1 !important"," animation-iteration-count")}
                ${c(".01s !important","animation-duration")}
            }
        `),
        n.states["big-cursor"] && (e += `
            body * {
                cursor: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 512 512'%3E%3Cpath  d='M429.742 319.31L82.49 0l-.231 471.744 105.375-100.826 61.89 141.083 96.559-42.358-61.89-141.083 145.549-9.25zM306.563 454.222l-41.62 18.259-67.066-152.879-85.589 81.894.164-333.193 245.264 225.529-118.219 7.512 67.066 152.878z' xmlns='http://www.w3.org/2000/svg'/%3E%3C/svg%3E\") ,default !important;
            }
        `)

        d(e, "dsw-content-style")
    }

    var f = function(e) {
        e.preventDefault();

        let a = e.currentTarget,
            s = a.dataset.key;

        if (a.classList.contains("dsw-filter")) {
            document.querySelectorAll(".dsw-filter").forEach(function(n) {
                n.classList.remove("dsw-selected"),
                n.setAttribute("aria-pressed", "false")
            })

            n.states.contrast = n.states.contrast !== s && s;
            n.states.contrast && (a.classList.add("dsw-selected"), a.setAttribute("aria-pressed", "true"));
            u(n.states.contrast);
        } else {
            n.states[s] = !n.states[s],
            a.classList.toggle("dsw-selected", n.states[s]);
            a.setAttribute("aria-pressed", n.states[s] ? "true" : "false");
            p();
        }

        t();
    }

    // adjust font size
    const m = function(t, e) {
        let a = !1;

        !e && t && (
          a = t.currentTarget,
          e = parseFloat(n.states.fontSize) || 1,
          a.classList.contains("dsw-minus") ? e -= .1 : e += .1,
          e = Math.max(e, .1),
          e = Math.min(e, 2),
          e = parseFloat(e.toFixed(2))
        );

        document.querySelectorAll("h1,h2,h3,h4,h5,h6,p,a,dl,dt,li,ol,th,td,span").forEach(function(n) {
            if (!n.classList.contains("material-icons")) {
                let t = n.getAttribute("data-dsw-orgFontSize");
                t || (t = parseInt(window.getComputedStyle(n, null).getPropertyValue("font-size")), n.setAttribute("data-dsw-orgFontSize", t));
                let a = t * e;

                n.style["font-size"] = a + "px"
            }
        });

        let s = "Default";
        1 !== e && (e > 1 ? s = "+" : e < 1 && (s = "-"), s += parseInt(100 * e) + "%");
        a && (a.parentElement.querySelector(".dsw-amount").innerHTML = s);
        e && (n.states.fontSize = e)
    }

    let h = r.querySelector(".dsw-menu"),
        g = r.querySelector(".dsw-overlay");

    r.querySelector(".dsw-menu-btn").addEventListener("click", function() {
        h.style.display = "block" == h.style.display ? "none" : "block", g.style.display = h.style.display
    }, !1)

    h.querySelector(".dsw-menu-close").addEventListener("click", function() {
        h.style.display = "none", g.style.display = h.style.display
    }, !1)

    g.addEventListener("click", function() {
        h.style.display = "none", g.style.display = h.style.display
    }, !1)

    h.querySelector(".dsw-menu-reset").addEventListener("click", function() {
        n.states = {};
        u();
        p();
        m(void 0, 1);

        document.querySelectorAll(".dsw-btn").forEach(function(n) {
            n.classList.remove("dsw-selected"), n.setAttribute("aria-pressed", "false")
        });

        document.querySelectorAll(".dsw-amount").forEach(function(n) {
            n.innerHTML = "Default"
        });

        t()
    }, !1)

    h.querySelectorAll(".dsw-btn").forEach(function(n) {
        n.addEventListener("click", f, !1)
    })

    h.querySelectorAll(".dsw-adjust-font div[role='button']").forEach(function(n) {
        n.addEventListener("click", m, !1)
    })

    document.body.appendChild(r);

    // m(null, .7);

    if (e) {
        p();
        m(null, n.states.fontSize || 1);
        n.states.contrast && u(n.states.contrast);
    }
};

document.addEventListener("DOMContentLoaded", init);
